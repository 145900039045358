import React, { Component } from 'react'

import Swiper from 'swiper/dist/js/swiper.min.js'

import { datas } from './localdatas'
import 'swiper/dist/css/swiper.min.css'
import styles from './index.module.scss'

let serverSwiper = null

class IncrementServer extends Component {
  componentDidMount () {
    this.initIncrementSwiper()
  }

  // 初始化swiper
  initIncrementSwiper = () => {
    /* eslint-disable */
    serverSwiper = new Swiper('#serverSwiper', {
      speed: 1000,
      autoplay: {
        autoplay: true,
        delay: 6500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }
    })
    /* eslint-enable */
  }

  // 点击上一页
  handleSwiperPrev = () => {
    serverSwiper.slidePrev()
  }

  // 点击下一页
  handleSwiperNext = () => {
    serverSwiper.slideNext()
  }

  render () {
    return (
      <div className={`${styles.increment}`}>
        <div className={`${styles.content}`}>
          <div className={'swiper-container'} id='serverSwiper'>
            <div className={'swiper-wrapper'}>
              {
                datas.map((item, index) => (
                  <div className={'swiper-slide'} key={item.id}>
                    <div className={`${styles.slideContent}`}>
                      <img className={`${styles.pic}`} src={item.image} />
                      <div className={`${styles.textWrap}`}>
                        <h3>NO.{ index + 1}</h3>
                        <h4>{ item.name }</h4>
                        <p dangerouslySetInnerHTML={{ __html: item.desc }} />
                      </div>
                      <div className={`${styles.slidePagination}`}>
                        <a className={`${styles.swiperButton} ${styles.buttonPrev}`} onClick={() => this.handleSwiperPrev()}></a>
                        <div className={`${styles.paginationContent}`}>
                          <div className={`${styles.page}`}>
                            <span className={`${styles.activePage}`}>{ index + 1 }/</span>6
                          </div>
                          <div className={`${styles.progressbar}`}>
                            <div className={`${styles.progressbarInner}`} style={{ width: `${(index + 1) * 25}px` }}></div>
                          </div>
                          <p className={`${styles.paginationDesc}`}>{ item.name }</p>
                        </div>
                        <a className={`${styles.swiperButton} ${styles.buttonNext}`} onClick={() => this.handleSwiperNext()}></a>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IncrementServer
