import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import styles from './index.module.scss'

class EnquireDetailModal extends Component {
  static propTypes = {
    content: PropTypes.string,
    onCancel: PropTypes.func
  }

  render () {
    const { content, onCancel } = this.props

    return (
      <Modal title={<div style={{ textAlign: 'center' }}>询价内容</div>} width={390}
        visible
        onCancel={() => onCancel()}
        footer={[
          <div key='btns' style={{ textAlign: 'center' }}>
            <Button type='primary' shape='round' className={`${styles.btn}`} onClick={() => onCancel()}>关闭</Button>
          </div>
        ]}
      >
        <div style={{ textAlign: 'center' }}>{ content }</div>
      </Modal>
    )
  }
}

export default EnquireDetailModal
