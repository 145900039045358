import React, { useState } from 'react'
import { message } from 'antd'
import $lodash from 'lodash'

import TrialModal from '@/pages/home/trialModal'

import Api from '@/common/api/index'
import styles from './index.module.scss'

export default function ContactLand () {
  const [trialModal, setTrialModal] = useState(false)

  // 申请添加
  const handleApply = (params) => {
    const formData = new FormData()
    $lodash.forEach(params, (values, key) => {
      if (values !== undefined) {
        formData.append(key, values)
      }
    })

    Api.saveApplyOnTrail(formData).then(res => {
      message.success('提交成功~')
      setTrialModal(false)
    })
  }

  return (
    <>
      <div className={`${styles.contactWrap}`}>
        <div className={`${styles.title}`}>联系客服为您提供免费试用服务</div>
        <a className={`${styles.btn}`} onClick={() => setTrialModal(true)}>申请演示</a>
      </div>

      {
        trialModal
          ? <TrialModal
              onConfirm={(params) => handleApply(params)}
              onCancel={() => setTrialModal(false)}
            />
          : null
      }
    </>
  )
}
