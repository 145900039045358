import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

import styles from './index.module.scss'

const FormItem = Form.Item

function TrialForm (props) {
  const { onCancel, onConfirm } = props
  const { getFieldDecorator } = props.form

  // 点击确定
  const handleConfirm = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        onConfirm(values)
      }
    })
  }

  return (
    <>
      <Modal title={<div className={`${styles.titleText}`}>填写试用申请</div>}
        width={520}
        visible
        maskClosable={false}
        getContainer = {false}
        footer={[
          <div key={'btns'} className={`${styles.btns}`}>
            <a className={`${styles.submitBtn}`} onClick={() => handleConfirm()}>提交试用申请</a>
          </div>
        ]}
        bodyStyle={{ padding: '24px 40px 40px 40px' }}
        onCancel={() => onCancel()}
      >
        <Form>
          <FormItem label='姓名' style={{ marginBottom: '20px' }}>
            {
              getFieldDecorator('user_name', {
                rules: [
                  { required: true, message: '请填写姓名' }
                ]
              })(
                <Input className={`${styles.formInput}`} autoComplete='off' />
              )
            }
          </FormItem>

          <FormItem label='联系方式' style={{ marginBottom: '20px' }}>
            {
              getFieldDecorator('user_call', {
                rules: [
                  { required: true, message: '请填写联系方式' }
                ]
              })(
                <Input className={`${styles.formInput}`} autoComplete='off' />
              )
            }
          </FormItem>

          <FormItem label='公司名称' style={{ marginBottom: '20px' }}>
            {
              getFieldDecorator('company', {
                rules: [
                  { required: true, message: '请填写公司名称' }
                ]

              })(
                <Input className={`${styles.formInput}`} autoComplete='off' />
              )
            }
          </FormItem>

          <FormItem label='邮箱' style={{ marginBottom: '20px' }}>
            {
              getFieldDecorator('user_mail', {

              })(
                <Input className={`${styles.formInput}`} autoComplete='off' />
              )
            }
          </FormItem>

          <p className={`${styles.tips}`}>
            温馨提示：<br />
            尊敬的客户，您好！为了保证您可以顺利试用我司产品，请填写真实的申请信息，提交成功后，工作人员将在3个工作日内与您联系，感谢您对我司产品的关注。
          </p>
        </Form>
      </Modal>
    </>
  )
}

TrialForm.propTypes = {
  form: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

const TrailModal = Form.create()(TrialForm)

export default memo(TrailModal)
