import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'

import styles from './index.module.scss'
import Logo from '@/assets/images/logo.svg'

class NavBar extends Component {
  static propTypes = {
    needfixed: PropTypes.bool,
    txtcolor: PropTypes.number
  }

  state = {}

  render () {
    const { needfixed = false, txtcolor } = this.props

    return (
      <header className={`${styles.siteHeader} ${needfixed ? styles.fixedHeader : styles.default}`}>
        <div className={`${styles.container} clearfix`}>
          <Link className={`${styles.navLogo}`} to={{ pathname: '/home' }}>
            <img src={Logo} />
          </Link>
          <ul className={`${styles.navMenu}`}>
            <li style={{ color: { txtcolor } }}>
              <NavLink className={`${styles.menuItem} ${txtcolor === 1 ? styles.istxt : styles.nstxt}`} activeClassName={styles.active} to={{ pathname: '/home' }}>首页</NavLink>
            </li>
            <li style={{ color: { txtcolor } }}>
              <NavLink className={`${styles.menuItem} ${txtcolor === 1 ? styles.istxt : styles.nstxt}`} activeClassName={styles.active} to={{ pathname: '/toolsbox' }}>工聚宝</NavLink>
            </li>
            <li style={{ color: { txtcolor } }}>
              <NavLink className={`${styles.menuItem} ${txtcolor === 1 ? styles.istxt : styles.nstxt}`} activeClassName={styles.active} to={{ pathname: '/supplyIntro' }}>供应平台</NavLink>
            </li>
            <li>
              <NavLink className={`${styles.menuItem} ${txtcolor === 1 ? styles.istxt : styles.nstxt} `} activeClassName={styles.active} to={{ pathname: '/aboutUs' }}>关于我们</NavLink>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}

export default NavBar
