import React from 'react'

import styles from './index.module.scss'

// 流程简介
export default function OperateIntro () {
  return (
    <>
      <div className={`${styles.operateWrap}`}>
        <h4 className={`${styles.title}`}>十分钟拓展您的销货渠道</h4>
        <div className={`${styles.content}`}>
          <div className={`${styles.stepItem}`}>
            <div className={`${styles.stepContent} ${styles.step46844}`}>
              <div className={`${styles.stepInner}`}>上传产品<br />云供应</div>
              <div className={`${styles.arrow}`} />
            </div>
            <p className={`${styles.stepDesc}`}>
              将您的价格导入，<br/> 一键配置价格和上架产品
            </p>
          </div>

          <div className={`${styles.stepItem}`}>
            <div className={`${styles.stepContent} ${styles.step46845}`}>
              <div className={`${styles.stepInner}`}>导入库存</div>
              <div className={`${styles.arrow}`} />
            </div>
            <p className={`${styles.stepDesc}`}>
              将您的库存表导入，<br />配置您的可售库存数量
            </p>
          </div>

          <div className={`${styles.stepItem}`}>
            <div className={`${styles.stepContent} ${styles.step46846}`}>
              <div className={`${styles.stepInner}`}>申请品牌<br />供应资格</div>
              <div className={`${styles.arrow}`} />
            </div>
            <p className={`${styles.stepDesc}`}>
              请上传您的代理信息，<br />将在10-20分总内审核完成
            </p>
          </div>

          <div className={`${styles.stepItem}`}>
            <div className={`${styles.stepContent} ${styles.step46847}`}>
              <div className={`${styles.stepInner}`}>审核通过</div>
            </div>
            <p className={`${styles.stepDesc}`}>
              您的产品正式发布到云仓，<br />客户成功经理会与您联系，<br />开启交易之旅
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
