import topSlideDefaultImage1 from '@/assets/images/scheme/top_default_1.png'
import topSlideDefaultImage2 from '@/assets/images/scheme/top_default_2.png'
import topSlideDefaultImage3 from '@/assets/images/scheme/top_default_3.png'
import topSlideDefaultImage4 from '@/assets/images/scheme/top_default_4.png'
import topSlideDefaultImage5 from '@/assets/images/scheme/top_default_5.png'
import topSlideDefaultImage6 from '@/assets/images/scheme/top_default_6.png'
import topSlideDefaultImage7 from '@/assets/images/scheme/top_default_7.png'
import topSlideDefaultImage8 from '@/assets/images/scheme/top_default_8.png'

import topSlideActiveImage1 from '@/assets/images/scheme/top_active_1.png'
import topSlideActiveImage2 from '@/assets/images/scheme/top_active_2.png'
import topSlideActiveImage3 from '@/assets/images/scheme/top_active_3.png'
import topSlideActiveImage4 from '@/assets/images/scheme/top_active_4.png'
import topSlideActiveImage5 from '@/assets/images/scheme/top_active_5.png'
import topSlideActiveImage6 from '@/assets/images/scheme/top_active_6.png'
import topSlideActiveImage7 from '@/assets/images/scheme/top_active_7.png'
import topSlideActiveImage8 from '@/assets/images/scheme/top_active_8.png'

import bottomSlideDefaultImage1 from '@/assets/images/scheme/bottom_default_1.png'
import bottomSlideDefaultImage2 from '@/assets/images/scheme/bottom_default_2.png'
import bottomSlideDefaultImage3 from '@/assets/images/scheme/bottom_default_3.png'
import bottomSlideDefaultImage4 from '@/assets/images/scheme/bottom_default_4.png'
import bottomSlideDefaultImage5 from '@/assets/images/scheme/bottom_default_5.png'
import bottomSlideDefaultImage6 from '@/assets/images/scheme/bottom_default_6.png'
import bottomSlideDefaultImage7 from '@/assets/images/scheme/bottom_default_7.png'
import bottomSlideDefaultImage8 from '@/assets/images/scheme/bottom_default_8.png'

import bottomSlideActiveImage1 from '@/assets/images/scheme/bottom_active_1.png'
import bottomSlideActiveImage2 from '@/assets/images/scheme/bottom_active_2.png'
import bottomSlideActiveImage3 from '@/assets/images/scheme/bottom_active_3.png'
import bottomSlideActiveImage4 from '@/assets/images/scheme/bottom_active_4.png'
import bottomSlideActiveImage5 from '@/assets/images/scheme/bottom_active_5.png'
import bottomSlideActiveImage6 from '@/assets/images/scheme/bottom_active_6.png'
import bottomSlideActiveImage7 from '@/assets/images/scheme/bottom_active_7.png'
import bottomSlideActiveImage8 from '@/assets/images/scheme/bottom_active_8.png'

export const datas = [
  {
    id: 'FIRST_SLIDE',
    datas: [
      {
        topDefaultImage: topSlideDefaultImage1,
        topActiveImage: topSlideActiveImage1,
        bottomDefaultImage: bottomSlideDefaultImage1,
        bottomActiveImage: bottomSlideActiveImage1,
        name: '标准化数据库',
        desc: '600+品牌<br/> 300+万条产品数据',
        id: 'FIRST_SLIDE_1'
      },
      {
        topDefaultImage: topSlideDefaultImage2,
        topActiveImage: topSlideActiveImage2,
        bottomDefaultImage: bottomSlideDefaultImage2,
        bottomActiveImage: bottomSlideActiveImage2,
        name: '极速建站',
        desc: '标准化B2B商城<br/>1天部署当天使用',
        id: 'FIRST_SLIDE_2'
      },
      {
        topDefaultImage: topSlideDefaultImage3,
        topActiveImage: topSlideActiveImage3,
        bottomDefaultImage: bottomSlideDefaultImage3,
        bottomActiveImage: bottomSlideActiveImage3,
        name: '全生命周期服务',
        desc: '从售前到项目落地<br/>提供7*24小时咨询服务',
        id: 'FIRST_SLIDE_3'
      },
      {
        topDefaultImage: topSlideDefaultImage4,
        topActiveImage: topSlideActiveImage4,
        bottomDefaultImage: bottomSlideDefaultImage4,
        bottomActiveImage: bottomSlideActiveImage4,
        name: '商机孵化',
        desc: '客户行为轨迹全跟踪<br/>提高用户转化率和复购率',
        id: 'FIRST_SLIDE_4'
      }
    ]
  },
  {
    id: 'SECOND_SLIDE',
    datas: [
      {
        topDefaultImage: topSlideDefaultImage5,
        topActiveImage: topSlideActiveImage5,
        bottomDefaultImage: bottomSlideDefaultImage5,
        bottomActiveImage: bottomSlideActiveImage5,
        name: '自动迭代',
        desc: '定期升级产品功能<br/>更符合工业客户使用习惯',
        id: 'SECOND_SLIDE_5'
      },
      {
        topDefaultImage: topSlideDefaultImage6,
        topActiveImage: topSlideActiveImage6,
        bottomDefaultImage: bottomSlideDefaultImage6,
        bottomActiveImage: bottomSlideActiveImage6,
        name: '智能供应链',
        desc: '打通千万家渠道，亿万现货库存<br/>快速增加销售通路',
        id: 'SECOND_SLIDE_6'
      },
      {
        topDefaultImage: topSlideDefaultImage7,
        topActiveImage: topSlideActiveImage7,
        bottomDefaultImage: bottomSlideDefaultImage7,
        bottomActiveImage: bottomSlideActiveImage7,
        name: '第三方服务商',
        desc: '对接物流、营销、保险等服务商<br/>加强商城运营能力',
        id: 'SECOND_SLIDE_7'
      },
      {
        topDefaultImage: topSlideDefaultImage8,
        topActiveImage: topSlideActiveImage8,
        bottomDefaultImage: bottomSlideDefaultImage8,
        bottomActiveImage: bottomSlideActiveImage8,
        name: '数字化解决方案',
        desc: '提供ERP+云商城方案<br/>实现企业运营效率最大化',
        id: 'SECOND_SLIDE_8'
      }
    ]
  }
]
