import React, { Component } from 'react'

import styles from './index.module.scss'

class FirstScreen extends Component {
  static propTypes = {

  }

  state = {}

  componentDidMount () {}

  render () {
    return (
      <div className={`${styles.firstScreen}`}>
        <div className={`${styles.container}`}>
          <a className={`${styles.btn}`} target='_blank' rel='noreferrer'
            href={PATH_ENV === 'prod' ? 'https://www.tool.iiasaas.com' : 'http://www.test-iia-toolbox.com'}
          >
            登录入口
          </a>
        </div>
      </div>
    )
  }
}

export default FirstScreen
