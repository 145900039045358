import React, { Component } from 'react'

import styles from './index.module.scss'

class IntroLand extends Component {
  render () {
    return (
      <div className={`${styles.introLand}`}>
        <div className={`${styles.container}`}>
          <ul className={`${styles.list}`}>
            <li className={`${styles.listItem}`}>
              <div className={`${styles.question}`}>为什么叫“工聚宝”？</div>
              <div className={`${styles.answer}`}>工代表工业品/工业行业，聚有两层含义，其一是代表资源能力的聚集和共享（以数智创享构建工业流通领域之互信共生），其二是代表这款应用泛用性强，聚集了个人用户日常工作中会使用到的各种功能。</div>
            </li>

            <li className={`${styles.listItem}`}>
              <div className={`${styles.question}`}>为什么做这个产品？</div>
              <div className={`${styles.answer}`}>在帮助中小企业解决电商转型、建设数字供应链过程中，我们发现企业的员工也非常缺乏可靠的工具来提升自身的工作能力和效率，比如销售员如何有效增加销售渠道和销售产出；商务和采购如何对接到更多的资源来补充自己的渠道、降低采购成本、提升对销售或者客户的回复效率。所以我们决定开发一款每个工业人都能用的资源管理工具（应用），并且通过制定活动规则，希望参与的每个工业人都能够获得回报、看到效果。</div>
            </li>

            <li className={`${styles.listItem}`}>
              <div className={`${styles.question}`}>系统为什么要收费？</div>
              <div className={`${styles.answer}`}>
              首先这个应用并不是直接的收费而是预付费制度，如果个人使用没有产生效果，费用会全部退回，所以请不用担心会增加个人成本。其次，市场上确实存在类似的系统，并且是免费的。我们之所以坚持预付费的方式，有以下几点原因：<br />
              1、我们希望通过预收费的方式，吸引到真正愿意参与项目的用户，因为客户端后台确实需要进行一部分操作。免费的方式当然可以吸引到更多的人参与，但是我们也知道工具再好，不好好使用和运营也不会产生任何效果，这样做也避免耽误用户和我们自己的时间，降低各自的成本；<br />
              2、通过预付费的方式，形成会员认知，每一个客户于我们而言都是最重要的会员，我们自己的销售、客户成功和实施团队都会以最高的服务质量来面对用户。而免费的方式要不就没有服务，要不非常容易形成服务认知的“不公平”，即大客户得到更好的服务而小客户甚至得不到服务；<br />
              3、因为制定了收费和退费的规则，所以会要求我们自己追求每一位用户的“客户成功”，甚至是体验超预期，这样才能实现用户和我们的双赢。免费反而是一种更简单的商业操作。
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default IntroLand
