import React, { memo } from 'react'

import PartTitle from '../component/partTitle/index'

import { datas } from './localdatas'
import styles from './index.module.scss'

// 行业痛点
function Industry () {
  return (
    <div className={`${styles.industry}`}>
      <PartTitle title='行业痛点' desc='Industry pain points' color={'#FFFFFF'} />

      <ul className={`${styles.content}`}>
        {
          datas.map((item, index) => (
            <li className={`${styles.industryItem}`} key={`industryItem-${index}`}>
              <img className={`${styles.pic} ${styles.defaultPic}`} src={item.defaultIcon} />
              <img className={`${styles.pic} ${styles.activePic}`} src={item.activeIcon} />
              <span>{ item.desc }</span>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default memo(Industry)
