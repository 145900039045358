import React, { Component } from 'react'

import PartTitle from '../component/partTitle/index'

import backgroundImage from '@/assets/images/first_screen_banner.jpg'
import bitmap01 from '@/assets/images/home/bitmap_01.png'
import bitmap02 from '@/assets/images/home/bitmap_02.png'
import bitmap03 from '@/assets/images/home/bitmap_03.png'

import styles from './index.module.scss'

export default class FirstScreen extends Component {
  render () {
    return (
      <div className={`${styles.main}`}>
        <div className={`${styles.firstScreen}`}>
          <img className={`${styles.backgroundImage}`} src={backgroundImage} />

          <div className={`${styles.firstContent}`}>
            <h3 className={`${styles.firstTitle}`}>工业品企数化服务运营商</h3>
            <p className={`${styles.firstDesc}`}>DIGITAL SERVICE PROVIDER OF INDUSTRIAL PRODUCTS</p>
          </div>
        </div>

        <div className={`${styles.entrance}`}>
          <PartTitle title='企数化服务' desc={'Enterprise digital service'} />

          <ul className={`${styles.entranceContent}`}>
            <li className={`${styles.entranceCol}`}>
              <div className={`${styles.imgBox}`}>
                <img className={`${styles.pic}`} src={bitmap01} />
              </div>
              <h4 className={`${styles.tit}`}>企数化建立</h4>
              <p>B2B商城+功能<br />私域流量的建立<br />初步形成企业核心数据</p>
            </li>

            <li className={`${styles.entranceCol}`}>
              <div className={`${styles.imgBox}`}><img className={`${styles.pic}`} src={bitmap02} /></div>
              <h4 className={`${styles.tit}`}>企数化运营</h4>
              <p>云ERP<br />数字化运营<br />存量客户提效降本</p>
            </li>

            <li className={`${styles.entranceCol}`}>
              <div className={`${styles.imgBox}`}><img className={`${styles.pic}`} src={bitmap03} /></div>
              <h4 className={`${styles.tit}`}>企数化营销</h4>
              <p>营销推广，数据分析<br />数字化营销<br />培养市场引流获客</p>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
