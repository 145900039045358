import React, { Component } from 'react'
import { message } from 'antd'
import $lodash from 'lodash'

import TrialModal from '@/pages/home/trialModal/index'

import Api from '@/common/api/index'
import styles from './index.module.scss'

class ContactLand extends Component {
  state = {}

  componentDidMount () {}

  // 申请添加
  handleApply = (params) => {
    const formData = new FormData()
    $lodash.forEach(params, (values, key) => {
      if (values !== undefined) {
        formData.append(key, values)
      }
    })

    Api.saveApplyOnTrail(formData).then(res => {
      message.success('提交成功~')
      this.closeModal()
    })
  }

  showTrialModal = () => {
    this.setState({
      trialModal: true
    })
  }

  closeModal = () => {
    this.setState({
      trialModal: false
    })
  }

  render () {
    const { trialModal = false } = this.state

    return (
      <>
        <div className={`${styles.contactLand}`}>
          <div className={`${styles.container}`}>
            <div className={`${styles.title}`}>联系客服为您提供免费试用服务!</div>
            <div className={`${styles.btn}`} onClick={() => this.showTrialModal()}>申请演示</div>
          </div>
        </div>

        {
          trialModal
            ? <TrialModal
                onConfirm={(values) => this.handleApply(values)}
                onCancel={() => this.closeModal()}
              />
            : null
        }
      </>
    )
  }
}

export default ContactLand
