
const REPORTBASE = {
  prod: 'http://tracking.iiasaas.com/officialWebTrack',
  test: 'http://192.168.1.13:7000/officialWebTrack',
  dev: 'http://192.168.1.13:7000/officialWebTrack'
}

const reportUrl = REPORTBASE[PATH_ENV]

/**
 * 动态创建img标签-上报埋点数据
 */
export const sendReportByImage = (params) => {
  params.domain = window.location.host
  let args = ''
  for (const i in params) {
    if (args !== '') {
      args += '&'
    }
    args += `${i}=${params[i]}`
  }

  const img = new Image()
  const imgSrc = reportUrl + '?' + args
  img.src = imgSrc
}

// /**
//  * POST-上报埋点数据
// */
// export const sendReportByPost = (params) => {
//   params['domain'] = window.location.host
//   let xhr = new XMLHttpRequest()
//   let data = new FormData()

//   for (let obj in params) {
//     data.append(obj, params[obj])
//   }
//   xhr.open('POST', reportUrl)
//   xhr.send(data)
// }
