/**
 * 首页&登录&注册 路由
 */
import Home from '@/pages/home/index'
import AboutUs from '@/pages/aboutus/index'
import ToolsBox from '@/pages/toolsbox/index'
import SupplyIntro from '@/pages/supplyIntro/index'

export default [
  { path: '/home', component: Home, name: '首页', auth: false },
  { path: '/aboutus', component: AboutUs, name: '关于我们', auth: false },
  { path: '/toolsbox', component: ToolsBox, name: '工聚宝', auth: false },
  { path: '/supplyIntro', component: SupplyIntro, name: '供应平台', auth: false }
]
