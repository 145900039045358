import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default function PartTitle (props) {
  const { title = '', desc = '', color = '' } = props

  return (
    <>
      <h3 className={`${styles.title}`} style={{ color: color }}>{ title }</h3>
      <p className={`${styles.desc}`} style={{ color: color }}>{ desc }</p>
    </>
  )
}

PartTitle.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  color: PropTypes.string
}
