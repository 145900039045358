import React, { Component } from 'react'
import $lodash from 'lodash'

import NavBar from '@/components/navbar/index'
import FirstScreen from './firstScreen/index'
import FeatureIntro from './featureIntro/index'
import OperateIntro from './operateIntro/index'
import ContactLand from './contactLand/index'
import Footer from '@/components/footer/index'

class SupplyIntro extends Component {
  static propTypes = {}
  state = {
    needfixed: false,
    txtcolor: 1
  }

  componentDidMount () {
    document.addEventListener('scroll', this.scrollHandler)
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.scrollHandler)
  }

  // 监听页面滚动
  scrollHandler = () => {
    // 页面滚动的距离
    const scrollTop = document.documentElement.scrollTop
    const listenerScroll = $lodash.throttle(() => {
      const { needfixed = false } = this.state

      if (scrollTop >= 150) {
        !needfixed && this.setState({ needfixed: true, txtcolor: 2 })
      } else if (scrollTop < 300) {
        needfixed && this.setState({ needfixed: false, txtcolor: 1 })
      }
    }, 300)

    listenerScroll()
  }

  render () {
    const { needfixed = false, txtcolor = 1 } = this.state

    return (
      <>
        <NavBar needfixed={needfixed} txtcolor = {txtcolor}></NavBar>
        <FirstScreen />
        <FeatureIntro />
        <OperateIntro />
        <ContactLand />
        <Footer />
      </>
    )
  }
}

export default SupplyIntro
