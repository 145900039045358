import React, { useEffect, useState } from 'react'
import $lodash from 'lodash'

import { Icon } from 'antd'
import styles from './index.module.scss'

function BackTop () {
  const [show, changeShow] = useState(false)

  useEffect(() => {
    const listener = $lodash.throttle(() => {
      const shouldShow = document.documentElement.scrollTop > 300

      if (shouldShow !== show) {
        changeShow(shouldShow)
      }
    }, 300)

    document.addEventListener('scroll', listener)
    return () => document.removeEventListener('scroll', listener)
  }, [show])

  return show
    ? <div className={`${styles.backTop}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
     <Icon className={styles.bg} type="caret-up" />
    </div>
    : null
}

export default BackTop
