import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import configureStore from './common/config/configureStore'

import './index.css'

const { persistor, store } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zhCN}><App /></ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
