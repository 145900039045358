import iconDefault1 from '@/assets/images/industry/icon_default_1.png'
import iconDefault2 from '@/assets/images/industry/icon_default_2.png'
import iconDefault3 from '@/assets/images/industry/icon_default_3.png'
import iconDefault4 from '@/assets/images/industry/icon_default_4.png'
import iconDefault5 from '@/assets/images/industry/icon_default_5.png'
import iconDefault6 from '@/assets/images/industry/icon_default_6.png'
import iconDefault7 from '@/assets/images/industry/icon_default_7.png'
import iconDefault8 from '@/assets/images/industry/icon_default_8.png'

import iconActive1 from '@/assets/images/industry/icon_active_1.png'
import iconActive2 from '@/assets/images/industry/icon_active_2.png'
import iconActive3 from '@/assets/images/industry/icon_active_3.png'
import iconActive4 from '@/assets/images/industry/icon_active_4.png'
import iconActive5 from '@/assets/images/industry/icon_active_5.png'
import iconActive6 from '@/assets/images/industry/icon_active_6.png'
import iconActive7 from '@/assets/images/industry/icon_active_7.png'
import iconActive8 from '@/assets/images/industry/icon_active_8.png'

export const datas = [
  {
    defaultIcon: iconDefault1,
    activeIcon: iconActive1,
    desc: '产品数据絮乱、缺少标准的产品数据与图片'
  },
  {
    defaultIcon: iconDefault2,
    activeIcon: iconActive2,
    desc: '专业化程度不足、升级迭代慢、成本高'
  },
  {
    defaultIcon: iconDefault3,
    activeIcon: iconActive3,
    desc: '商城平台开发成本高、周期长'
  },
  {
    defaultIcon: iconDefault4,
    activeIcon: iconActive4,
    desc: '缺少全链路建设和管理的能力'
  },
  {
    defaultIcon: iconDefault5,
    activeIcon: iconActive5,
    desc: '缺乏电商运营经验、和没有引流获客的方法'
  },
  {
    defaultIcon: iconDefault6,
    activeIcon: iconActive6,
    desc: '难以打通第三方服务商、形成服务竞争力'
  },
  {
    defaultIcon: iconDefault7,
    activeIcon: iconActive7,
    desc: '用户行为无法跟踪、商机无法转化为订单'
  },
  {
    defaultIcon: iconDefault8,
    activeIcon: iconActive8,
    desc: '线上线下业务割裂、无法打通完整业务流程'
  }
]
