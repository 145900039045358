import React from 'react'

import styles from './index.module.scss'

export default function FirstScreen () {
  return (
    <>
      <div className={`${styles.firstScreen}`}>
        <div className={`${styles.content}`}>
          <div className={`${styles.title}`}>IIASaaS供应平台</div>
          <p className={`${styles.description}`}>以高效、专业、便捷的服务理念，致力于建设工业流通领域的数字化供应链</p>
          <a className={`${styles.btn}`} target='_blank' rel='noopener noreferrer'
            href={PATH_ENV === 'prod' ? 'https://www.iia-eselling.com' : 'http://www.test-iia-eselling.com'}
          >登录入口</a>
        </div>
      </div>
    </>
  )
}
