import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row } from 'antd'

import Verifition from '@/components/verifition/verifySlide'

export default class VerifyModal extends Component {
  static propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {}

  componentDidMount () { }

  render () {
    return (
      <Modal title='请完成安全验证' bodyStyle={{ padding: '15px' }}
        width={360}
        visible
        maskClosable={false}
        footer={null}
        onCancel={this.props.onCancel}
      >
        <Row>
          <Verifition
            onSuccess={(params) => this.props.onConfirm(params)}
          />
        </Row>
      </Modal>
    )
  }
}
