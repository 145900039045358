import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Input, message } from 'antd'
import $lodash from 'lodash'

import VerifyModal from '@/components/verify-modal/index'

import Api from '@/common/api/index'

import styles from './index.module.scss'

const downloadFileUrl = 'https://iia-public-picture.china-iia.com/%E5%B7%A5%E8%81%9A%E5%AE%9D%E7%B3%BB%E7%BB%9F%E7%A7%9F%E8%B5%81%E5%90%88%E5%90%8C.doc'

class ContractForms extends Component {
  static propTypes = {
    form: PropTypes.object
  }

  state = {
    rowDetail: {},
    yztime: 59,
    btnDisabled: false
  }

  // 添加合同下载记录
  saveDownloadToolsboxContractRecord = async (params) => {
    const res = await Api.saveDownloadToolsboxContractRecord(params)
    const { code } = res
    if (+code === 10000) {
      // 执行下载功能
      window.open(downloadFileUrl)
    }
  }

  // 发送短信验证码
  sendSmsCaptchaAndValidate = async (params) => {
    const res = await Api.sendSmsCaptchaAndValidate(params)
    const { code } = res
    if (+code === 10000) {
      this.setInterval()
    } else {
      this.setState({
        yztime: 59,
        btnDisabled: false
      })
    }
  }

  // 校验验证码
  checkSendVerificationCode = async (params) => {
    const res = await Api.checkSendVerificationCode(params)
    const { code } = res
    if (+code === 10000) {
      this.saveDownloadToolsboxContractRecord(params)
    } else {
      message.warning(res.message)
    }
  }

  // 点击下载合同
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const mobile = $lodash.trim(values.mobile)
        if (mobile === '') {
          return message.warning('请填写正确的手机号码')
        }

        this.checkSendVerificationCode({ ...values, mobile: mobile, code: $lodash.trim(values.code) })
      }
    })
  }

  // 图形验证成功回调
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      const { rowDetail = {} } = this.state

      this.sendSmsCaptchaAndValidate({ ...params, ...rowDetail })
    })
  }

  // 点击发送验证码
  showVerifyModal = () => {
    const { btnDisabled = false } = this.state

    if (btnDisabled) { return false }

    const mobile = this.props.form.getFieldValue('mobile')

    if (!mobile || $lodash.trim(mobile) === '') {
      return message.warning('请填写正确的手机号码')
    }

    this.setState({
      rowDetail: { mobile: $lodash.trim(mobile) },
      verifyModal: true
    })
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  closeModal = () => {
    this.setState({
      verifyModal: false
    })
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { verifyModal = false, btnDisabled = false, yztime = 59 } = this.state

    return (
      <>
        <div className={`${styles.contractformLand}`}>
          <div className={`${styles.container}`}>
            <div className={`${styles.title}`}>了解更多有关工聚宝的服务信息，请填写信息下载合同</div>
            <p className={`${styles.subtitle}`}>承诺效果，无效退款；努力追求所有参与方的商业成功</p>

            <div className={`${styles.formWrap}`}>
              <Form>
                <Row gutter={80}>
                  <Col span={12}>
                    <Form.Item label={<><label style={{ color: '#FFFFFF' }}>姓名</label><span style={{ marginLeft: '12px', fontSize: '12px', color: '#999999' }}>真实姓名只支持中文</span></>}>
                      {
                        getFieldDecorator('name', {
                          rules: [
                            { required: true, message: '请填写姓名' }
                          ]
                        })(
                          <Input autoComplete='off' />
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<><label style={{ color: '#FFFFFF' }}>公司名称</label></>}>
                      {
                        getFieldDecorator('companyName', {
                          rules: [
                            { required: false, message: '请填写公司名称' }
                          ]
                        })(
                          <Input autoComplete='off' />
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={80}>
                  <Col span={12}>
                    <Form.Item label={<><label style={{ color: '#FFFFFF' }}>手机号</label></>}>
                      {
                        getFieldDecorator('mobile', {
                          rules: [
                            { required: true, message: '请填写手机号码' }
                          ]
                        })(
                          <Input autoComplete='off' />
                        )
                      }
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<><label style={{ color: '#FFFFFF' }}>验证码</label></>}>
                    {
                      getFieldDecorator('code', {
                        rules: [
                          { required: true, message: '请填写验证码' }
                        ]
                      })(
                        <Input autoComplete='off'
                          addonAfter={<a className={`${styles.addonBtn} ${btnDisabled ? styles.disabled : ''}`} onClick={() => this.showVerifyModal()}>{ !btnDisabled ? '获取验证码' : `${yztime}s重新发送` }</a>}
                        />
                      )
                    }
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={`${styles.btns}`}>
                  <div className={`${styles.btn}`} onClick={() => this.handleSubmit()}>下载合同</div>
                </Row>
              </Form>
            </div>
          </div>
        </div>

        {
          verifyModal
            ? <VerifyModal
                onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
                onCancel={() => this.closeModal()}
              />
            : null
        }
      </>
    )
  }
}

const ContractFormLand = Form.create()(ContractForms)

export default ContractFormLand
