import React, { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './title.module.scss'

function Title (props) {
  const { title, textColor } = props

  return (
    <h3 className={`${styles.title}`} style={{ color: `${textColor || ''}` }}>{ title }</h3>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  textColor: PropTypes.string
}

export default memo(Title)
