import React from 'react'
import { Row, Col } from 'antd'

import PRODUCT_1 from '@/assets/images/toolsbox/product_1.png'
import PRODUCT_2 from '@/assets/images/toolsbox/product_2.png'
import PRODUCT_3 from '@/assets/images/toolsbox/product_3.png'
import PRODUCT_4 from '@/assets/images/toolsbox/product_4.png'
import PRODUCT_5 from '@/assets/images/toolsbox/product_5.png'
import PRODUCT_6 from '@/assets/images/toolsbox/product_6.png'

import styles from './index.module.scss'

export default function ProductLand () {
  return (
    <div className={`${styles.productLand}`}>
      <div className={`${styles.container}`}>
        <div className={`${styles.title}`}>产品特色</div>

        <Row gutter={24}>
          <Col span={8}>
            <div className={`${styles.listitem}`}>
              <div className={`${styles.listitemPrefix}`}>
                <img className={`${styles.icon}`} src={PRODUCT_1} />
              </div>
              <div className={`${styles.listitemMain}`}>
                <div className={`${styles.name}`}>承诺效果</div>
                <p className={`${styles.description}`}>承诺销售额，使用没有效果，费用全部退回</p>
              </div>
            </div>
          </Col>

            <Col span={8}>
              <div className={`${styles.listitem}`}>
                <div className={`${styles.listitemPrefix}`}>
                  <img className={`${styles.icon}`} src={PRODUCT_2} />
                </div>
                <div className={`${styles.listitemMain}`}>
                  <div className={`${styles.name}`}>标准化产品数据库</div>
                  <p className={`${styles.description}`}>1000+品牌、300万条产品数据商品体系数字化运营，统一商品源头及口径</p>
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className={`${styles.listitem}`}>
                <div className={`${styles.listitemPrefix}`}>
                  <img className={`${styles.icon}`} src={PRODUCT_3} />
                </div>
                <div className={`${styles.listitemMain}`}>
                  <div className={`${styles.name}`}>一键询价</div>
                  <p className={`${styles.description}`}>一键导入询价单、极速回复</p>
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className={`${styles.listitem}`}>
                <div className={`${styles.listitemPrefix}`}>
                  <img className={`${styles.icon}`} src={PRODUCT_4} />
                </div>
                <div className={`${styles.listitemMain}`}>
                  <div className={`${styles.name}`}>智能供应链</div>
                  <p className={`${styles.description}`}>承诺销售额，使用没有效果，费用全部退回</p>
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className={`${styles.listitem}`}>
                <div className={`${styles.listitemPrefix}`}>
                  <img className={`${styles.icon}`} src={PRODUCT_5} />
                </div>
                <div className={`${styles.listitemMain}`}>
                  <div className={`${styles.name}`}>增值服务</div>
                  <p className={`${styles.description}`}>对接物流、保险等服务商，从接单到发货一站解决</p>
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className={`${styles.listitem}`}>
                <div className={`${styles.listitemPrefix}`}>
                  <img className={`${styles.icon}`} src={PRODUCT_6} />
                </div>
                <div className={`${styles.listitemMain}`}>
                  <div className={`${styles.name}`}>库存发布</div>
                  <p className={`${styles.description}`}>一键发布现货库存，品牌推广、呆货曝光</p>
                </div>
              </div>
            </Col>
          </Row>
      </div>
    </div>
  )
}
