import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { connect } from 'react-redux'

import RouterScrollTop from './components/routerScrollTop/index'
import BackTop from './components/backTop/index'

import { routerMap } from './routes/index'

class App extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    userInfo: PropTypes.object
  }

  state = {}

  componentDidMount () {

  }

  render () {
    const { userInfo = {} } = this.props

    return (
      <>
        <Router>
          <RouterScrollTop {...this.props}>
            <Switch>
              <Route exact path='/'
                render={props => { return <Redirect to='/home' /> }}
              />

              {
                routerMap.map((route, index) => (
                  <Route name={route.name} key={index}
                    exact
                    path={route.path}
                    render={props => (
                      route.auth && (!userInfo.token || userInfo.token === '')
                        ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                        : <route.component {...props} />
                    )}
                  />
                ))
              }
            </Switch>
          </RouterScrollTop>
        </Router>

        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

export default connect(mapStateToProps)(App)
