import React, { Component } from 'react'
import { Row, Col, Carousel } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import styles from './index.module.scss'

// 商品类型
const skuGoodsTypeMaps = [
  { id: 'SPOT', name: '现货', aliasName: '现货' },
  { id: 'FUTURES', name: '期货', aliasName: '期货' },
  { id: 'CLOUD_SPOT', name: '云现货', aliasName: '现货调货' },
  { id: 'CLOUD_FUTURES', name: '云期货', aliasName: '期货调货' }
]

class TradeLand extends Component {
  static propTypes = {}
  state = {
    tableData: []
  }

  componentDidMount () {
    this.getEnquireTradedGoodsList()
  }

  // 获取成交列表
  getEnquireTradedGoodsList = async () => {
    const res = await Api.getEnquireTradedGoodsList({ pageNo: 1, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || []
      })
    }
  }

  renderSkuGoodsTypeLabel = (status) => {
    const obj = skuGoodsTypeMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <>
        <div className={`${styles.tradeLand}`}>
          <div className={`${styles.container}`}>
            <div className={`${styles.header}`}>今日成交</div>
            <div className={`${styles.body}`}>
              <Row className={`${styles.thead}`}>
                <Col span={3}><div className={`${styles.th}`}>品牌</div></Col>
                <Col span={3}><div className={`${styles.th}`}>系列</div></Col>
                <Col span={4}><div className={`${styles.th}`}>型号</div></Col>
                <Col span={4}><div className={`${styles.th}`}>订货号</div></Col>
                <Col span={4}><div className={`${styles.th}`}>产品名称</div></Col>
                <Col span={3}><div className={`${styles.th}`}>产品类型</div></Col>
                <Col span={3}><div className={`${styles.th}`}>成交时间</div></Col>
              </Row>

              <div className={`${styles.carouselWrap}`}>
                {
                  tableData.length > 0
                    ? <Carousel dotPosition='right' autoplay dots={false}>
                      {
                        tableData.map((record, index) => (
                          <Row key={`${record.commoditySkuId}-${record.skuGoodsType}-${index}`} className={`${styles.tbody}`}>
                            <Col span={3}>
                              <div className={`${styles.td}`}>
                                <div>
                                  <p><label className={`${styles.label}`}>中</label>{ record.brandName }</p>
                                  <p><label className={`${styles.label}`}>英</label>{ record.englishBrandName }</p>
                                </div>
                              </div>
                            </Col>
                            <Col span={3}>
                              <div className={`${styles.td}`}>{ record.series }</div>
                            </Col>
                            <Col span={4}>
                              <div className={`${styles.td}`} title={record.model}>{ record.model }</div>
                            </Col>
                            <Col span={4}>
                              <div className={`${styles.td}`} title={record.itemNumber}>{ record.itemNumber }</div>
                            </Col>
                            <Col span={4}>
                              <div className={`${styles.td}`} title={record.name}>{ record.name }</div>
                            </Col>
                            <Col span={3}>
                              <div className={`${styles.td}`}>{ this.renderSkuGoodsTypeLabel(record.skuGoodsType) }</div>
                            </Col>
                            <Col span={3}>
                              <div className={`${styles.td}`}>{ record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD') : null }</div>
                            </Col>
                          </Row>
                        ))
                      }
                    </Carousel>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TradeLand
