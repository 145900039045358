import serverImage1 from '@/assets/images/incrementServer/server_1.png'
import serverImage2 from '@/assets/images/incrementServer/server_2.png'
import serverImage3 from '@/assets/images/incrementServer/server_3.png'
import serverImage4 from '@/assets/images/incrementServer/server_4.png'
import serverImage5 from '@/assets/images/incrementServer/server_5.png'
import serverImage6 from '@/assets/images/incrementServer/server_6.png'

export const datas = [
  {
    id: 'INCREMENT_SLIDE_1',
    name: '咨询服务',
    desc: '经营现状分析<br/>内部流程梳理<br/>线上培训及运营落地',
    image: serverImage1
  },
  {
    id: 'INCREMENT_SLIDE_2',
    name: '数据服务',
    desc: '数据标准化<br/>数据治理及数据应用',
    image: serverImage2
  },
  {
    id: 'INCREMENT_SLIDE_3',
    name: '供应链服务',
    desc: '覆盖全行业<br/>提供全品牌全品类<br/>供应链服务',
    image: serverImage3
  },
  {
    id: 'INCREMENT_SLIDE_4',
    name: '增值服务',
    desc: '物流服务<br/>保险服务<br/>尽享大客户优惠',
    image: serverImage4
  },
  {
    id: 'INCREMENT_SLIDE_5',
    name: '营销服务',
    desc: '品牌推广<br/>现货共享<br/>呆货曝光',
    image: serverImage5
  },
  {
    id: 'INCREMENT_SLIDE_6',
    name: '商业模式服务',
    desc: '线上与线下业务联动<br/>低成本高效服务客户',
    image: serverImage6
  }
]
