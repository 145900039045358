import axios from 'axios'
import pathConfig from '../config/pathConfig'
import { message } from 'antd'

const { host } = pathConfig
const instance = axios.create({
  baseURL: host, // 默认基础请求url
  timeout: 60000, // 请求60s超时
  headers: {
    'Content-type': 'application/json;charset=utf-8'
  }
})

// http request 拦截器
instance.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

// http response响应器
instance.interceptors.response.use(response => {
  return Promise.resolve(response.data)
}, error => {
  message.destroy()

  const { status, data } = error.response

  switch (status) {
    case 400:
      message.warning(data.message)
      break
    case 401:
      message.warning(data.message)
      break
    case 500:
      message.error('服务器开小差了，请稍后再试！')
      break
  }

  return Promise.reject(error)
})

export default instance
