import React, { Component } from 'react'
import Swiper from 'swiper'
import CountUp from 'react-countup'
import $lodash from 'lodash'

import PartTitle from '../component/partTitle/index'

import Api from '@/common/api/index'
import { datas } from './localdatas'

import 'swiper/dist/css/swiper.min.css'

import styles from './index.module.scss'
/* eslint-disable */
let superioritySwiper = null

export default class Superiority extends Component {
  state = {
    faly: true,
    tableData: []
  }

  componentDidMount () {
    this.getApplyNumber()
  }

  getApplyNumber = async () => {
    const res = await Api.getApplyNum({ type: 'data_settings' })
    const newResult = $lodash.map(res, (value, key) => {
      return { key: $lodash.toUpper(key), value: value }
    })

    const newdatas = $lodash.map(datas, (record) => {
      const _key = $lodash.split(record.id, '_')[2]
      const obj = $lodash.find(newResult, (o) => { return o.key === _key })

      return {
        ...record,
        value: obj !== undefined ? (['PARTNERSTOTAL', 'BRANDTOTAL'].includes(_key) ? `${obj.value}+` : parseInt(obj.value)) : record.value
      }
    })

    this.setState({
      tableData: newdatas
    }, () => {
      this.initSuperioritySwiper()
    })
  }

  // 初始化swiper
  initSuperioritySwiper = () => {
    /* eslint-disable */
    superioritySwiper = new Swiper('#superioritySwiper', {
      slidesPerView: 5,
      spaceBetween: 28,
      navigation: {
        nextEl: '.superiority-swiper-next',
        prevEl: '.superiority-swiper-prev',
      },
      pagination: {
        el: '#superiorityPagination',
        type: 'progressbar',
        clickable : true
      },
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false
      }
    })
    /* eslint-enable */
  }

  render () {
    const { faly, tableData = [] } = this.state

    return (
    <div className={`${styles.superiority}`}>
      <PartTitle title='我们能为你带来' desc={'We can bring you'} />
        {
          faly
            ? <>
              <div className={`${styles.content}`}>
                <div className={`swiper-container ${styles.superioritySwiper}`} id='superioritySwiper'>
                  <div className={`swiper-wrapper ${styles.superiorityWrapper}`}>
                    {
                      tableData.map((item) => (
                        <div className={`swiper-slide ${styles.brickItem}`} key={item.id}>
                          <div className={`${styles.imgBox}`}>
                            <img className={`${styles.pic} ${styles.defaultPic}`} src={item.defaultImage} />
                            <img className={`${styles.pic} ${styles.activePic}`} src={item.activeImage} />
                          </div>

                          <div className={`${styles.title}`}>
                            {
                              item.id.includes('TOTALAMOUNT')
                                ? <CountUp end={item.value} redraw={true} separator=',' />
                                : <>{item.value}</>
                            }
                          </div>
                          <p className={`${styles.desc}`}>{ item.name }</p>
                        </div>
                      ))
                    }
                  </div>

                  {/* 按钮 */}
                  <div className={`swiper-button-next superiority-swiper-next ${styles.buttonNext}`} />
                  <div className={`swiper-button-prev superiority-swiper-prev ${styles.buttonPrev}`} />

                  <div className={`swiper-pagination ${styles.superiorityPagination}`} id='superiorityPagination'></div>
                </div>
              </div>
            </>
            : null
        }
    </div>
    )
  }
}
