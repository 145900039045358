import React from 'react'

import styles from './index.module.scss'

// 产品特色
export default function FeatureIntro () {
  return (
    <>
      <div className={`${styles.featureWrap}`}>
        <h4 className={`${styles.title}`}>产品特色</h4>
        <ul className={`${styles.content}`}>
          <li className={`${styles.listItem} ${styles.item1}`}>
            <div className={`${styles.name}`}>行业标准产品数据库</div>
            <p className={`${styles.desc}`}>行业SKU标准化管理，一键匹配导入。</p>
          </li>
          <li className={`${styles.listItem} ${styles.item2}`}>
            <div className={`${styles.name}`}>库存发布</div>
            <p className={`${styles.desc}`}>
              实时库存发布，增加销货渠道、呆货曝光，提供库存对接服务。
           </p>
          </li>
          <li className={`${styles.listItem} ${styles.item3}`}>
            <div className={`${styles.name}`}>拉动增长</div>
            <p className={`${styles.desc}`}>
              提供客户成功经理，1对1无差别高质量服务客户，及时响应，协助成功。
            </p>
          </li>
          <li className={`${styles.listItem} ${styles.item4}`}>
            <div className={`${styles.name}`}>发货业务</div>
            <p className={`${styles.desc}`}>
              对接物流、保险等服务商，从接单到发货一站解决
            </p>
          </li>
          <li className={`${styles.listItem} ${styles.item5}`}>
            <div className={`${styles.name}`}>数字化解决方案</div>
            <p className={`${styles.desc}`}>
              提供ERP对接等服务，帮助企业解决数据孤岛问题。
            </p>
          </li>
          <li className={`${styles.listItem} ${styles.item6}`}>
            <div className={`${styles.name}`}>智能供应链</div>
            <p className={`${styles.desc}`}>
              实现云客户&报价&订单协同，供应商可以实时<br />
              在线报价、接单。助您达成更多交易
            </p>
          </li>
        </ul>
      </div>
    </>
  )
}
