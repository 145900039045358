import React, { Component } from 'react'
import $lodash from 'lodash'
import moment from 'moment'

import NavBar from '@/components/navbar/index'
import FirstScreen from './firstScreen'
import ProductLand from './productLand'
import ContractFormLand from './contractFormLand'
import TradeLand from './tradeLand'
import EnquireLand from './enquireLand'
import ContactLand from './contactLand'
import IntroLand from './introLand'
import Footer from '@/components/footer'

import { sendReportByImage } from '@/common/utils/reportFunc'
import { getStore } from '@/common/utils/mUtils'

class ToolsBox extends Component {
  static propTypes = {}
  state = {
    needfixed: false,
    txtcolor: 1,
    triggerTime:moment().format('YYYY-MM-DD HH:mm:ss')
  }

  constructor (props) {
    super(props)
    this.state = {
      triggerTime: null
    }
  }

  componentDidMount () {
    // 记录进入时间
    const triggerTime = moment().format('YYYY-MM-DD HH:mm:ss')
    this.setState({ triggerTime })

    document.addEventListener('scroll', this.scrollHandler)
  }

  componentWillUnmount () {
    // 退出触发埋点
    const { triggerTime } = this.state
    sendReportByImage({
      page: 'toolsbox',
      triggerTime,
      endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      userId: getStore('token')
    })

    document.removeEventListener('scroll', this.scrollHandler)
  }

  // 监听页面滚动
  scrollHandler = () => {
    // 页面滚动的距离
    const scrollTop = document.documentElement.scrollTop
    const listenerScroll = $lodash.throttle(() => {
      const { needfixed = false } = this.state

      if (scrollTop >= 150) {
        !needfixed && this.setState({ needfixed: true, txtcolor: 2 })
      } else if (scrollTop < 300) {
        needfixed && this.setState({ needfixed: false, txtcolor: 1 })
      }
    }, 300)

    listenerScroll()
  }

  render () {
    const { needfixed = false, txtcolor } = this.state

    return (
      <>
        <NavBar needfixed={needfixed} txtcolor = {txtcolor}></NavBar>
        <FirstScreen />
        <ProductLand />
        <ContractFormLand />
        <TradeLand />
        <EnquireLand />
        <ContactLand />
        <IntroLand />
        <Footer />
      </>
    )
  }
}

export default ToolsBox
