import React, { Component } from 'react'

import Swiper from 'swiper/dist/js/swiper.min.js'

import { datas } from './localdatas'

import 'swiper/dist/css/swiper.min.css'
import styles from './index.module.scss'

/* eslint-disable */
let schemeSwiper = null

// 解决方案
class Scheme extends Component {
  static propTypes = {}
  state = {
    activeId: 'FIRST_SLIDE_1'
  }

  componentDidMount () {
    this.initSchemeSwiper()
  }

  initSchemeSwiper = () => {
    /* eslint-disable */
    schemeSwiper = new Swiper('#schemeSwiper', {
      speed: 1000,
      navigation: {
        nextEl: '.scheme-swiper-next',
        prevEl: '.scheme-swiper-prev',
      },
      pagination: {
        el: '.scheme-pagination',
        type: 'progressbar'
      },
    })
    /* eslint-enable */
  }

  handleMouseEnter = (id) => {
    this.setState({
      activeId: id
    })
  }

  handleMouseLeave = () => {
    this.setState({
      activeId: null
    })
  }

  render () {
    const { activeId = 'FIRST_SLIDE_1' } = this.state

    return (
      <div className={`${styles.scheme}`}>
        <div className={`${styles.content}`}>
          <div className={'swiper-container'} id='schemeSwiper'>
            <div className={'swiper-wrapper'}>
              {
                datas.map((slide) => (
                  <div className={'swiper-slide'} key={`slide-${slide.id}`}>
                    <ul className={`${styles.slideContent}`}>
                      {
                        slide.datas.map((item, index) => (
                          <li className={`${styles.slideItem} ${activeId === item.id ? styles.active : ''}`} key={`${slide.id}-${index}`} onMouseEnter={() => this.handleMouseEnter(item.id)} onMouseLeave={() => this.handleMouseLeave()}>
                            <div className={`${styles.topSlideContent}`}>
                              <img className={`${styles.topPic} ${styles.topDefaultPic}`} src={item.topDefaultImage} />
                              <img className={`${styles.topPic} ${styles.topActivePic}`} src={item.topActiveImage} />
                              <h4 className={`${styles.topName}`}>{ item.name }</h4>
                            </div>

                            <div className={`${styles.bottomSlideContent}`}>
                              <img className={`${styles.pic} ${styles.defaultPic}`} src={item.bottomDefaultImage} />
                              <img className={`${styles.pic} ${styles.activePic}`} src={item.bottomActiveImage} />
                              <h4 className={`${styles.name}`}>{ item.name }</h4>
                              <p className={`${styles.desc}`} dangerouslySetInnerHTML={{ __html: item.desc }} />

                              <div className={`${styles.iconRight}`}></div>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ))
              }
            </div>

            {/* 按钮 */}
            <div className={`swiper-button-next scheme-swiper-next ${styles.buttonNext}`} />
            <div className={`swiper-button-prev scheme-swiper-prev ${styles.buttonPrev}`} />

            {/* 分页器 */}
            <div className={`swiper-pagination scheme-pagination ${styles.schemePagination}`} />
          </div>
        </div>
      </div>
    )
  }
}

export default Scheme
