import React, { Component } from 'react'
import $lodash from 'lodash'

import NavBar from '@/components/navbar/index'
import Footer from '@/components/footer/index'

import backgroundImage from '@/assets/images/first_screen_banner.jpg'
import styles from './index.module.scss'

export default class AboutUs extends Component {
  static propTypes = {}
  state = {
    needfixed: false,
    txtcolor: 1
  }

  componentDidMount () {
    document.addEventListener('scroll', this.scrollHandler)
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.scrollHandler)
  }

  // 监听页面滚动
  scrollHandler = () => {
    // 页面滚动的距离
    const scrollTop = document.documentElement.scrollTop
    const listenerScroll = $lodash.throttle(() => {
      const { needfixed = false } = this.state

      if (scrollTop >= 150) {
        !needfixed && this.setState({ needfixed: true, txtcolor: 2 })
      } else if (scrollTop < 300) {
        needfixed && this.setState({ needfixed: false, txtcolor: 1 })
      }
    }, 300)

    listenerScroll()
  }

  render () {
    const { needfixed = false, txtcolor } = this.state

    return (
      <div className={`${styles.container}`}>
        <NavBar needfixed={needfixed} txtcolor={txtcolor}></NavBar>
        <section className={`${styles.aboutSection}`}>
          <div className={`${styles.firstScreen}`}>
            <img className={`${styles.backgroundImage}`} src={backgroundImage} />

            <div className={`${styles.firstContent}`}>
              <h3 className={`${styles.firstTitle}`}>关于萨斯云</h3>
              <p className={`${styles.firstDesc}`}>ABOUT IIASaaS</p>
            </div>
          </div>

          <ul className={`${styles.content}`}>
          <li>
              <label className={`${styles.label}`}>ABOUT US</label>
              <div className={`${styles.title}`}>关于我们</div>
               <div className={styles.divp}>
                <p>
                 萨斯云科技（上海）有限责任公司是一家持续创新的工业品企数化服务运营商
                </p>
              </div>
            </li>

            <li>
              <label className={`${styles.label}`}>VISION AND MISSION</label>
              <div className={`${styles.title}`}>愿景与使命</div>
              <div className={styles.divp}>
              <p>愿景：以数智创享，构建工业流通领域之互信共生<br/>
                 使命：成人达己，助力合作伙伴实现数字化运营建设竞争新优势
               </p>
               </div>
            </li>

            <li>
              <label className={`${styles.label}`}>OUR COMMITMENT</label>
              <div className={`${styles.title}`}>我们的承诺</div>
              <div className={styles.divp}>
              <p>
              安全服务：萨斯云基于国内专业的网络服务供应商的优质服务，以确保每个合作伙伴的数据和信息安全 <br/>
              独立性原则：基于公正、公平独立性原则基础上，追求所有参与方的商业成功
              </p>
              </div>
            </li>

            <li>
              <label className={`${styles.label}`}>VALUE</label>
              <div className={`${styles.title}`}>萨斯云价值</div>
              <div className={`${styles.valueContent}`}>
                <div className={`${styles.valueCol}`}>高效</div>

                <div className={`${styles.valueCol}`}>专业</div>

                <div className={`${styles.valueCol}`}>便捷</div>
              </div>
            </li>

          </ul>
        </section>

        <Footer></Footer>
      </div>
    )
  }
}
