import Ajax from './ajax'
import FormRequest from './formrequest'

export default {
  // 申请合作
  saveApplyOnTrail (params) {
    return FormRequest.post('/merchantapply', params)
  },

  // 合作数量
  getApplyNum (params) {
    return Ajax.get('/synthetical', { params })
  },

  // 获取询价列表
  getPublishedEnquireInformations (params) {
    return Ajax.post('/java/enquiry/order/manage/center/listPageEnquiryNotice', params)
  },

  // 每日交易信息- 获取成交产品列表
  getEnquireTradedGoodsList (params) {
    return Ajax.post('/java/enquiry/order/manage/center/listPageByCloudOrderGoods', params)
  },

  // 发送短信验证码
  sendSmsCaptchaAndValidate (params) {
    return Ajax.get('/java/api/sms/nologin/send', { params })
  },

  // 校验短信验证码
  checkSendVerificationCode (params) {
    return Ajax.get('/java/api/sms/nologin/check', { params })
  },

  // 官网合同下载记录添加入库
  saveDownloadToolsboxContractRecord (params) {
    return Ajax.post('/java/sms/official/website/contract/download/add', params)
  },

  /* 滑动验证码START */
  // 获取验证图片信息
  getCaptchaVerifyInfo (params) {
    return Ajax.post('/java/api/captcha/get', params)
  },

  // 校验滑块验证码
  checkCaptchaVerify (params) {
    return Ajax.post('/java/api/captcha/check', params)
  }
  /* 滑动验证码END */
}
