import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Badge, Divider } from 'antd'

import LOGOURL from '@/assets/images/LOGO.png'
import WXLOGOURL from '@/assets/images/vxlogo.jpg'

import styles from './index.module.scss'

function Footer () {
  return (
    <div className={`${styles.footer}`}>

      <div className={`${styles.footerLinks}`}>
        <img className={`${styles.flexPic}`} src={LOGOURL} />
        <div className={`${styles.linksGroup}`}>
          <div className={`${styles.menuItem}`}>
            <h4 className={`${styles.menuTitle}`}><Badge color='#20A9FB' /><span>产品中心</span></h4>
            <ul className={`${styles.menuList}`}>
              <li>
                <a target='_blank' rel='noreferrer' href={PATH_ENV === 'prod' ? 'https://www.tool.iiasaas.com' : 'http://www.test-iia-toolbox.com'}>工聚宝</a>
              </li>
              <li>
                <a target='_blank' rel='noopener noreferrer' href={PATH_ENV === 'prod' ? 'https://www.iia-eselling.com' : 'http://www.test-iia-eselling.com'}>IIASaaS供应平台</a>
              </li>
            </ul>
          </div>
          <div className={`${styles.menuItem}`}>
            <h4 className={`${styles.menuTitle}`}><Badge color='#20A9FB' /><span>关于我们</span></h4>
            <ul className={`${styles.menuList}`}>
              <li>
                <Link to={{ pathname: '/aboutUs' }}>公司介绍</Link>
              </li>
              <li>
                <Link to={{ pathname: '/aboutUs' }}>愿景与使命</Link>
              </li>
              <li><Link to={{ pathname: '/aboutUs' }}>我们的承诺</Link></li>
            </ul>
          </div>
          <div className={`${styles.menuItem}`}>
            <h4 className={`${styles.menuTitle}`}><Badge color='#20A9FB' /><span>联系我们</span></h4>
            <ul className={`${styles.menuList}`}>
              <li>18521003803</li>
              <li>jun.shi@iiasaas.com</li>
              <li>上海市闵行区浦江镇江月路 1388号3号楼255室</li>
            </ul>
          </div>
        </div>
        <div className={`${styles.rightBox}`}>
          <img className={`${styles.wxPic}`} src={WXLOGOURL} />
          <p>关注我们</p>
          <p>萨斯云科技官方微信公众号</p>
        </div>
      </div>
      <Divider className={`${styles.divider}`} />
      <div className={`${styles.copyRights}`}>
        <div>
          CopyRight&#169;IIASaaS&nbsp;2019&nbsp;-&nbsp;{ moment().format('YYYY') }&nbsp;Corporation All Rights Reserved&nbsp;
          <a className={`${styles.link}`} href='https://beian.miit.gov.cn/' target='_blank' rel='noopener noreferrer'>
            沪ICP备19030095号-52
          </a>
        </div>

        <div style={{ marginTop: '20px' }}>
          <a className={`${styles.link}`} href='https://picture.china-iia.com/business/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7%28%E6%B0%B4%E5%8D%B0%29.jpg' target='_blank' rel='noopener noreferrer'>
            营业执照
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
