import React, { memo, useState, useEffect } from 'react'
import $lodash from 'lodash'
import Title from '../component/title'
// import LazyLoad from 'react-lazyload'
import Api from '@/common/api/index'
import moment from 'moment'
import styles from './index.module.scss'
import { list, Aimg } from './brandimgs'

function Brand () {
  const [ischeck, Setischeck] = useState('A')
  const [brandImages, Setimg] = useState(Aimg)
  const [txt, Settxt] = useState('查看更多')
  const [listt, Setlist] = useState([])
  const [type, Settype] = useState(true)

  const getischeck = (item) => {
    Setischeck(item.dec)
    const obj = $lodash.find(list, (e) => { return e.dec === item.dec })
    Setimg(obj.name || [])
    const id = document.getElementById('btn')
    id.style.maxHeight = '600px'
    Settxt('查看更多')
  }
  useEffect(() => {
    getApplyNum()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ischeck])

  // 初始化获取品牌列表
  const getApplyNum = () => {
    const param = {
      type: 'brand_logos',
      time: moment().unix()
    }
    Api.getApplyNum(param).then(res => {
      const newdata = []
      $lodash.forEach(res, (values, key) => {
        const newArr = $lodash.map(values, (item) => {
          return {
            brandName: item[0],
            imageUrl: item[2],
            linkUrl: item[4]
          }
        })
        newdata.push({
          key: key,
          values: newArr
        })
      })
      const newlist = $lodash.filter(newdata, item => { return item.key === ischeck })
      Setlist(newlist.length > 0 ? newlist[0].values : newlist)
      showbtn()
    })
  }
  useEffect(() => {
    showbtn()
  }, [brandImages])

  // 是否显示按钮
  const showbtn = () => {
    const id = document.getElementById('btn').childNodes.item(0).childNodes
    Settype(id.length > 20)
  }
  // 点击按钮事件
  const changebtn = () => {
    const id = document.getElementById('btn')
    if (txt === '查看更多') {
      id.style.maxHeight = ''
      id.style.height = 'auto'
      Settxt('收起来')
    } else {
      id.style.maxHeight = '600px'
      Settxt('查看更多')
    }
  }
  // 链接跳转
  const getUrl = (linkUrl) => {
    window.open(linkUrl)
  }

  return (

    <div className={`${styles.brand}`}>
      <Title title='我们的品牌' />
      <div className={`${styles.content}`}>
        <div className={`${styles.filterLand}`}>
          <span className={`${styles.label}`}>首字母检索</span>
          {
            list.map((item, n) => (
              <span className={`${styles.decspan} ${ischeck === item.dec ? styles.ischeck : ''}`} key={`filterItem-${n}`} onClick={() => { getischeck(item) }}>
                <a className={`${styles.adec}`}>{item.dec}</a>
              </span>
            ))
          }
        </div>

        <div id='btn' style={{ maxHeight: '600px', overflow: 'hidden' }}>
          <ul className={`${styles.brick} clearfix`}>
            {
              listt.map((brand, i) => (
                <li className={`${styles.brickItem}`} key={`brandItem-${i}`}>
                  <a onClick={() => { getUrl(brand.linkUrl) }} className={styles.info}>
                    <img className={`${styles.pic}`} src={brand.imageUrl} />
                    <div className={styles.txt}>{brand.brandName}</div>
                  </a>
                </li>
              ))
            }
          </ul>
        </div>

        {
          type
            ? <div className={styles.btn}><a onClick={() => { changebtn() }}>{txt}</a></div>
            : null
        }
      </div>
    </div>
  )
}

export default memo(Brand)
