import React, { Component } from 'react'

import defaultIconImage from '@/assets/images/cooperation/icon_location.png'
import activeIconImage from '@/assets/images/cooperation/icon_location_hover.png'

import styles from './index.module.scss'

class Cooperation extends Component {
  render () {
    return (
      <div className={`${styles.cooperation}`}>
        <h3 className={`${styles.title}`}>合作伙伴及服务商</h3>
        <p className={`${styles.subtitle}`}>Partners and service</p>

        <div className={`${styles.content}`}>
          <div className={`${styles.map}`}>

            <div className={`${styles.region} ${styles.position1}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>北京</div>
                </div>
              </div>

            </div>

            <div className={`${styles.region} ${styles.position2}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>天津</div>
                </div>
              </div>
            </div>

            <div className={`${styles.region} ${styles.position3}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>上海</div>
                </div>
              </div>
            </div>

            <div className={`${styles.region} ${styles.position4}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>广州</div>
                </div>
              </div>
            </div>

            <div className={`${styles.region} ${styles.position5}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>深圳</div>
                </div>
              </div>
            </div>

            <div className={`${styles.region} ${styles.position6}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`}src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>成都</div>
                </div>
              </div>
            </div>

            <div className={`${styles.region} ${styles.position7}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>西安</div>
                </div>
              </div>
            </div>

            <div className={`${styles.region} ${styles.position8}`}>
              <div className={`${styles.mitoWrap}`}>
                <div className={`${styles.mito}`}>
                  <img className={`${styles.defaultIcon}`} src={defaultIconImage} />
                  <img className={`${styles.activeIcon}`} src={activeIconImage} />
                  <div className={`${styles.name}`}>福州</div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.groupMap}`}></div>
        </div>
      </div>
    )
  }
}

export default Cooperation
