import React, { memo, useState } from 'react'
import TrialModal from '../trialModal/index'
import { message } from 'antd'
import Api from '@/common/api/index'
import $lodash from 'lodash'

import backgroundImage from '@/assets/images/promote/promote_background.png'

import styles from './index.module.scss'

// 申请使用
function Promote (props) {
  const [visibile, changeVisibile] = useState(false)

  // 申请添加
  const HandleApply = (params) => {
    const formData = new FormData()
    $lodash.forEach(params, (values, key) => {
      if (values !== undefined) {
        formData.append(key, values)
      }
    })

    Api.saveApplyOnTrail(formData).then(res => {
      message.success('提交成功~')
      changeVisibile(false)
    })
  }

  return (
    <>
      <div className={`${styles.promote}`}>
        <img className={`${styles.backgroundImage}`} src={backgroundImage} />
        <div className={`${styles.content}`}>
          <h3 className={`${styles.title}`}>为您提供可信赖的企业专属方案</h3>
          <p className={`${styles.desc}`}>我们精心为您提供了产品体验活动</p>
          <a className={`${styles.btn}`} onClick={() => changeVisibile(true)}>立即申请</a>
        </div>
      </div>

      {
        visibile
          ? <TrialModal
              onConfirm={(params) => HandleApply(params)}
              onCancel={() => changeVisibile(false)}
            />
          : null
      }
    </>
  )
}

export default memo(Promote)
