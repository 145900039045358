import iconDefault1 from '@/assets/images/superiority/icon_default_1.png'
import iconDefault2 from '@/assets/images/superiority/icon_default_2.png'
import iconDefault3 from '@/assets/images/superiority/icon_default_3.png'
import iconDefault4 from '@/assets/images/superiority/icon_default_4.png'
import iconDefault5 from '@/assets/images/superiority/icon_default_5.png'
import iconDefault6 from '@/assets/images/superiority/icon_default_6.png'

import iconActive1 from '@/assets/images/superiority/icon_active_1.png'
import iconActive2 from '@/assets/images/superiority/icon_active_2.png'
import iconActive3 from '@/assets/images/superiority/icon_active_3.png'
import iconActive4 from '@/assets/images/superiority/icon_active_4.png'
import iconActive5 from '@/assets/images/superiority/icon_active_5.png'
import iconActive6 from '@/assets/images/superiority/icon_active_6.png'

export const datas = [
  {
    id: 'SUPERIORITY_SLIDE_PARTNERSTOTAL',
    name: '合作伙伴数量(位)',
    value: '',
    defaultImage: iconDefault1,
    activeImage: iconActive1
  },
  {
    id: 'SUPERIORITY_SLIDE_2',
    name: '合作伙伴业务增长(%)',
    value: '30%+',
    defaultImage: iconDefault2,
    activeImage: iconActive2
  },
  {
    id: 'SUPERIORITY_SLIDE_3',
    name: '合作伙伴运营成本下降(%)',
    value: '-20%',
    defaultImage: iconDefault3,
    activeImage: iconActive3
  },
  {
    id: 'SUPERIORITY_SLIDE_SKUTOTAL',
    name: '库存有效SKU品类(个)',
    value: '',
    defaultImage: iconDefault4,
    activeImage: iconActive4
  },
  {
    id: 'SUPERIORITY_SLIDE_TOTALAMOUNT',
    name: '今日现货总金额(￥)',
    value: '',
    defaultImage: iconDefault5,
    activeImage: iconActive5
  },
  {
    id: 'SUPERIORITY_SLIDE_BRANDTOTAL',
    name: '品牌总数(个)',
    value: '',
    defaultImage: iconDefault6,
    activeImage: iconActive6
  }
]
