
export const Aimg = []
export const list = [
  { id: 1, dec: 'A' },
  { id: 2, dec: 'B' },
  { id: 3, dec: 'C' },
  { id: 4, dec: 'D' },
  { id: 5, dec: 'E' },
  { id: 6, dec: 'F' },
  { id: 7, dec: 'G' },
  { id: 8, dec: 'H' },
  { id: 9, dec: 'I' },
  { id: 10, dec: 'J' },
  { id: 11, dec: 'K' },
  { id: 12, dec: 'L' },
  { id: 13, dec: 'M' },
  { id: 14, dec: 'N' },
  { id: 15, dec: 'O' },
  { id: 16, dec: 'P' },
  { id: 17, dec: 'Q' },
  { id: 18, dec: 'R' },
  { id: 19, dec: 'S' },
  { id: 20, dec: 'T' },
  { id: 21, dec: 'U' },
  { id: 22, dec: 'V' },
  { id: 23, dec: 'W' },
  { id: 24, dec: 'X' },
  { id: 25, dec: 'Y' },
  { id: 26, dec: 'Z' },
  { id: 27, dec: '0-9' }
]
