import React, { Component } from 'react'
import { Row, Col } from 'antd'

import EnquireDetailModal from '../enquireDetailModal'

import Api from '@/common/api/index'

import styles from './index.module.scss'

class EnquireLand extends Component {
  static propTypes = {}
  state = {
    tableData: [],
    enquireContent: null,
    detailModal: false
  }

  componentDidMount () {
    this.getPublishedEnquireInformations()
  }

  // 获取询价列表
  getPublishedEnquireInformations = async () => {
    const res = await Api.getPublishedEnquireInformations({ pageNo: 1, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || []
      })
    }
  }

  showDetailModal = (record) => {
    this.setState({
      enquireContent: record.enquiryContent,
      detailModal: true
    })
  }

  closeModal = () => {
    this.setState({
      enquireContent: '',
      detailModal: false
    })
  }

  render () {
    const { tableData = [], detailModal = false, enquireContent } = this.state

    return (
      <>
        <div className={`${styles.enquireLand}`}>
          <div className={`${styles.container}`}>
            <div className={`${styles.header}`}>今日询价</div>
            <div className={`${styles.body}`}>
              <Row className={`${styles.thead}`} gutter={24}>
                <Col span={18} className={`${styles.th}`}>询价内容</Col>
                <Col span={3} className={`${styles.th}`}>联系人</Col>
                <Col span={3} className={`${styles.th}`}>联系方式</Col>
              </Row>
              {
                tableData.map((record, index) => (
                  <Row key={`enquireRow-${record.id}-${index}`} className={`${styles.tbody}`} gutter={24}>
                    <Col span={18} className={`${styles.td}`}>
                      <a className={`${styles.link}`} onClick={() => this.showDetailModal(record)}>{ record.enquiryContent }</a>
                    </Col>
                    <Col span={3} className={`${styles.td}`}>{ record.contactsUserName }</Col>
                    <Col span={3} className={`${styles.td}`}>{ record.contactsMobile }</Col>
                  </Row>
                ))
              }
            </div>
          </div>
        </div>

        {
          detailModal
            ? <EnquireDetailModal
                content={enquireContent}
                onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default EnquireLand
