import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import $lodash from 'lodash'
import moment from 'moment'

import NavBar from '@/components/navbar/index'
import FirstScreen from './firstScreen/index'
import Superiority from './superiority/index' // 优势
import Industry from './industry/index' // 行业痛点
import Scheme from './scheme/index' // 解决方案
import IncrementServer from './incrementServer/index' // 增值服务
import Cooperation from './cooperation/index' // 合作伙伴
import Brand from './brand/index' // 我们的品牌
import Promote from './promote/index' // 试用
import Footer from '@/components/footer/index'

import { sendReportByImage } from '@/common/utils/reportFunc'
import { getStore } from '@/common/utils/mUtils'

class Home extends Component {
  static propTypes = {

  }

  state = {
    needfixed: false,
    txtcolor: 1
  }

  constructor (props) {
    super(props)
    this.state = {
      triggerTime: null
    }
  }

  componentDidMount () {
    // 记录进入时间
    const triggerTime = moment().format('YYYY-MM-DD HH:mm:ss')
    this.setState({ triggerTime })

    document.addEventListener('scroll', this.scrollHandler)
  }

  componentWillUnmount () {
    // 退出触发埋点
    const { triggerTime } = this.state
    sendReportByImage({
      page: 'home',
      triggerTime,
      endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      userId: getStore('token')
    })

    document.removeEventListener('scroll', this.scrollHandler)
  }

  // 监听页面滚动
  scrollHandler = () => {
    // 页面滚动的距离
    const scrollTop = document.documentElement.scrollTop

    const listenerScroll = $lodash.throttle(() => {
      const { needfixed = false } = this.state

      if (scrollTop >= 150) {
        !needfixed && this.setState({ needfixed: true, txtcolor: 2 })
      } else if (scrollTop < 300) {
        needfixed && this.setState({ needfixed: false, txtcolor: 1 })
      }
    }, 300)

    listenerScroll()
  }

  render () {
    const { needfixed = false, txtcolor } = this.state

    return (
      <>
        <NavBar needfixed={needfixed} txtcolor = {txtcolor}></NavBar>
        <FirstScreen />

        <Superiority />
        <Industry />
        <Scheme />
        <IncrementServer />

        <Cooperation />
        <Brand />
        <Promote />

        <Footer />

      </>
    )
  }
}

export default withRouter(Home)
